import { useEffect } from "react"
import { handleSignOut } from "../components/Auth/services/authActions"

export default () => {
  useEffect(() => {
    handleSignOut({ redirect: true })
  }, [])

  return null
}
